<template>  
  <div 
    class="modal-view"
    @contextmenu.prevent.stop=""
    @scroll.prevent=""
  >
    <div
      class="modal-background"
      :class="{'modal-background-disappearing': closingModal}"
      @click="close()"
    />

    <div
      class="modal-body enter-from-left"
      :class="{'leave-to-bottom': closingModal}"
    >
      <div class="modal-window modal-window-full"
        :class="{
          'modal-window-pink': (!showTransfer && transactionData.sum && +transactionData.sum < 0),
          'modal-window-green': (!showTransfer && transactionData.sum && +transactionData.sum > 0),
        }"
      >
        <div 
          v-if="loading"
          class="modal-content"
        >
          {{ $filters.localizeFilter('Loading') }}...
        </div>

        <div
          v-else
          class="modal-content"
        >
          <h3 class="modal-header">
            <span v-if="showTransfer">
              {{ $filters.localizeFilter('AccountTransfer') }}
            </span>

            <span v-else-if="+transactionData.sum > 0">
              {{ $filters.localizeFilter('Refil') }}
            </span>

            <span v-else-if="+transactionData.sum < 0">
              {{ $filters.localizeFilter('Withdrawal') }}
            </span>

            <span v-else>
              {{ $filters.localizeFilter('AddTransaction') }}
            </span>
          </h3>

          <div class="modal-block colToRow">
            <div class="flex_column mb-0_5 pr-0_5 width_100">
              <p class="modal-text fw-bold fs-small">
                {{ $filters.localizeFilter('Sum') }}
              </p>
              
              <TransactionSum
                :set-sum="transactionData.sum"
                :set-autofocus="!lessonStep"
                @chosen-new-sum="newTransactionSum($event)"
              />
            </div>

            <div class="width_3_5" />

            <div class="flex_column mb-0_5 pr-0_5 width_100">
              <p class="modal-text fw-bold fs-small">
                {{ $filters.localizeFilter('Date') }}
              </p>

              <div class="vertical_align_center cursor-pointer flex_row">
                <div
                  class="flex_row hover_opacity-0_75"
                  @click.prevent.stop="showDatePicker = true"
                >
                  <div class="text-sm vertical_align_center width_5 color-main">
                    {{ $filters.dateFilter(transactionData.date) }}
                  </div>

                  <div class="width_1_25 vertical_align_center">
                    <div class="icon icon-grey icon-calendar_today square-1_2 vertical_align_center" />
                  </div>
                </div>

                <TransferButton
                  v-if="numberOfTransactionsCanAdd > 1 && canUseTransfers"
                  v-tooltip="{
                    text: $filters.localizeFilter('AccountTransfer'),
                    padding: 1
                  }"
                  class="flex-center vertical_align_center ml-0_5"
                  :transfer="showTransfer"
                  @clicked="transferButtonClicked()"
                />

                <GoToHelpButton
                  class="ml-0_25"
                  helpsection="Transactions"
                  location="AccountTransfer"
                  tooltip-location="top"
                />
              </div>
            </div>
          </div>

          <div class="modal-block">
            <hr
              v-if="showTransfer"
              class="mt-0_5"
            >
          </div>

          <div class="modal-block colToRow width_100">
            <div
              class="flex_column"
              :class="{'maxWidthHalf': showTransfer, 'maxWidthFull': !showTransfer}"
            >
              <p class="modal-text fw-bold fs-small">
                <span v-if="transactionData.sum > 0">{{ $filters.localizeFilter('EnrollmentAccount') }}</span>
                <span v-else-if="transactionData.sum < 0">{{ $filters.localizeFilter('DebitAccount') }}</span>
                <span v-else>{{ $filters.localizeFilter('Account') }}</span>
              </p>

              <TransactionAccount
                :key="transactionData.accountId"
                :set-account-id="transactionData.accountId"
                :set-card-id="(getLimitNumber('maxCards') && transactionData.cardId) ? transactionData.cardId : null"
                :show-invalid="!accountValid"
                from="add-transaction"
                @set-new-account="setNewAccount($event)"
                @save-new-account="setNewAccount($event)"
              />

              <div class="min-h-1_5">
                <CountLimit
                  v-if="transactionData.accountId && accounts[transactionData.accountId] && accounts[transactionData.accountId].currencyCode"
                  class="mt-0_25 ml-0_25"
                  :account-id="transactionData.accountId"
                  :transaction-sum="transactionData.sum"
                  @sum-before-clicked="+accounts[transactionData.accountId].sum ? transactionData.sum = +accounts[transactionData.accountId].sum : null"
                />
              </div>

              <div
                v-if="canUseLimit('transactionComment', true)"
                class="flex_column mt-0_5"
              >
                <p class="modal-text fw-bold fs-small">
                  {{ $filters.localizeFilter('Comment') }}
                </p>

                <TransactionComment
                  :set-comment-text="transactionData.comment"
                  @comment-text-input="newComment($event, 'transactionData')"
                />
              </div>

              <div class="mt-0_25 mt-0_75">
                <p class="modal-text fw-bold fs-small">
                  {{ $filters.localizeFilter('Tags') }}
                </p>

                <div class="tags-container">
                  <Tag
                    v-for="(tagId) in transactionData.tags"
                    :key="tagId"
                    :tag-id="tagId"
                    :show-delete="true"
                    :no-action="true"
                    @delete-tag="deleteTag($event, 'transactionData')"
                  />

                  <AddTagButton
                    :clickable="!transactionData.tags || !transactionData.tags.length || transactionData.tags.length < getLimitNumber('tagsInTransaction')"
                    @add-tag-clicked="showAddTagMenuClicked('transactionData')"
                  />
                </div>
              </div>
            </div>

            <hr
              v-if="showTransfer && numberOfTransactionsCanAdd > 1 && canUseTransfers"
              class="hidden-hr mt-0_5 mb-0_5"
            >

            <div
              v-if="showTransfer && numberOfTransactionsCanAdd > 1 && canUseTransfers"
              class="switchMarginTop inline_flex_justify_center width_8pc"
            >
              <div
                v-if="numberOfTransactionsCanAdd > 1 && canUseTransfers && transactionData.accountId && transferTransactionData.accountId"
                v-tooltip="{
                  text: $filters.localizeFilter('SwapAccounts'),
                  padding: 1
                }"
                class="icon icon-swap_horiz icon-grey-second cursor-pointer square-1_5"
                @click.prevent.stop="changeAccounts()"
              />
            </div>

            <div
              v-if="showTransfer && numberOfTransactionsCanAdd > 1 && canUseTransfers"
              class="maxWidthHalf flex_column"
            >              
              <p class="modal-text fw-bold fs-small">
                <span v-if="transactionData.sum > 0">{{ $filters.localizeFilter('DebitAccount') }}</span>
                <span v-else-if="transactionData.sum < 0">{{ $filters.localizeFilter('EnrollmentAccount') }}</span>
                <span v-else>{{ $filters.localizeFilter('Account') }}</span>
              </p>

              <TransactionAccount
                :key="transferTransactionData.accountId"
                :set-account-id="transferTransactionData.accountId"
                :set-card-id="(getLimitNumber('maxCards') && transferTransactionData.cardId) ? transferTransactionData.cardId : null"
                :hide-accounts="[transactionData.accountId]"
                :currency-code="setCurrency()"
                :show-invalid="!transferAccountValid"
                :no-default-account="true"
                from="add-transaction"
                @set-new-account="setNewTransferAccount($event)"
                @save-new-account="setNewTransferAccount($event)"
              />

              <div class="min_height_1_5">
                <CountLimit
                  v-if="transferTransactionData.accountId && accounts[transferTransactionData.accountId] && accounts[transferTransactionData.accountId].currencyCode"
                  class="mt-0_25 ml-0_5"
                  :account-id="transferTransactionData.accountId"
                  :transaction-sum="transferTransactionData.sum"
                  @sum-before-clicked="+accounts[transferTransactionData.accountId].sum ? transactionData.sum = +accounts[transferTransactionData.accountId].sum : null"
                />
              </div>
           
              <div
                v-if="canUseLimit('transactionComment', true) && numberOfTransactionsCanAdd > 1 && canUseTransfers"
                class="flex_column mt-0_5"
              >
                <p class="modal-text fw-bold fs-small">
                  {{ $filters.localizeFilter('Comment') }}
                </p>

                <TransactionComment
                  :set-comment-text="transferTransactionData.comment"
                  @comment-text-input="newComment($event, 'transferTransactionData')"
                />
              </div>

              <div
                v-if="numberOfTransactionsCanAdd > 1 && canUseTransfers"
                class="flex_column mt-0_75"
              >
                <p class="modal-text fw-bold fs-small">
                  {{ $filters.localizeFilter('Tags') }}
                </p>

                <div class="tags-container">
                  <Tag
                    v-for="(tagId) in transferTransactionData.tags"
                    :key="tagId"
                    :tag-id="tagId"
                    :show-delete="true"
                    :no-action="true"
                    @delete-tag="deleteTag($event, 'transferTransactionData')"
                  />

                  <AddTagButton
                    :clickable="!transferTransactionData.tags || !transferTransactionData.tags.length || transferTransactionData.tags.length < getLimitNumber('tagsInTransaction')"
                    @add-tag-clicked="showAddTagMenuClicked('transferTransactionData')"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-buttons">
            <SaveButton
              :text="$filters.localizeFilter('Save')"
              :clickable="canSave"
              @clicked="save()"
            />
          </div>
        </div>

        <div class="modal-top-right-buttons-block">
          <GoToHelpButton
            class="cursor-pointer"
            helpsection="Transactions"
            location="AddTransaction"
            tooltip-location="top"
          />

          <CloseButton
            @clicked="close()"
          />
        </div>

        <AddTagMenu
          v-if="showAddTagMenu"
          :exclude-tags="excludeTags"
          :scroll-allowed="true"
          @close-pannel="showAddTagMenu = false"
          @clicked-tag="newTag($event)"
          @clicked-tags-collection="newTagsCollection($event)"
        />
      </div>

      <DatePicker
        v-if="showDatePicker"
        :set-date="+transactionData.date"
        @close-popup="showDatePicker = false"
        @chosen-new-date="newTransactionDate($event)"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { logAnalyticsEvent } from '@/firebase/analytics'
import checkInputValueMixin from '@/mixins/checkInputValue.mixin'
import setScrollAllowedAction from '@/mixins/setScrollAllowedAction.mixin'
import GoToHelpButton from '@/components/UI/buttons/GoToHelpButton'
import CloseButton from '@/components/UI/buttons/CloseButton'
import SaveButton from '@/components/UI/buttons/SaveButton'
import TransactionAccount from '@/components/views/Transactions/TransactionAccount'
import TransactionComment from '@/components/views/Transactions/TransactionComment'
import TransactionSum from '@/components/views/Transactions/TransactionSum'

export default {
  name: 'AddTransactionComponent',
  components: {
    GoToHelpButton, CloseButton, SaveButton, TransactionAccount, TransactionComment, TransactionSum,
    AddTagButton: defineAsyncComponent(() => import('@/components/views/Tags/AddTagButton')),
    AddTagMenu: defineAsyncComponent(() => import('@/components/views/Tags/AddTagMenu')),
    CountLimit: defineAsyncComponent(() => import('@/components/views/Transactions/CountLimit')),
    DatePicker: defineAsyncComponent(() => import('@/components/UI/DatePicker')),
    Tag: defineAsyncComponent(() => import('@/components/views/Tags/Tag')),
    TransferButton: defineAsyncComponent(() => import('@/components/UI/buttons/TransferButton'))
  },
  mixins: [checkInputValueMixin, setScrollAllowedAction],
  data: () => ({
    loading: true,
    emptyData: {
      accountId: null,
      cardId: null,
      comment: null,
      date: null,
      sum: null,
      tags: []
    },
    transactionData: {},
    transactionOldData: {},
    transferTransactionData: {},
    transferTransactionOldData: {},
    dateValid: false,
    sumValid: false,
    commentValid: true,
    transferCommentValid: true,
    showAddTagMenu: false,
    showTransfer: false,
    trns: 'transactionData',
    closingModal: false,
    showDatePicker: false
  }),
  computed: {
    ...mapGetters([
      'accounts',
      'canUseLimit',
      'cards',
      'clickedTransactionId',
      'getLimitNumber',
      'lessonStep',
      'tags',
      'transactions'
    ]),
    numberOfTransactionsCanAdd() {
      return this.$store.getters.getAvailableLimitNumber('transactionsReset', 'transactions', 'maxTransactions')
    },
    canUseTransfers () {
      return this.$store.getters.canUseLimit('transfers', false)
    },
    excludeTags() {
      if (this.trns === 'transactionData') {
        return this.transactionData.tags
      } else if (this.trns === 'transferTransactionData') {
        return this.transferTransactionData.tags
      }
      return []
    },
    needSave () {
      if ((JSON.stringify(this.transactionData) === JSON.stringify(this.transactionOldData)) && (JSON.stringify(this.transferTransactionData) === JSON.stringify(this.transferTransactionOldData))) { return false }
      return true
    },
    accountValid () {
      if (!this.transactionData.accountId) { return false }
      return true
    },
    transferAccountValid() {
      if (!this.showTransfer) { return true }
      if (!this.transferTransactionData.accountId) { return false }
      if (this.sameAccounts)  { return false }
      if (!this.sameCurrency)  { return false }
      return true
    },
    formInvalid () {
      if (!this.dateValid || !this.sumValid || !this.accountValid || !this.transferAccountValid || !this.commentValid || !this.transferCommentValid) { return true }
      if (this.sameAccounts) { return true }
      if (!this.sameCurrency) { return true }
      return false
    },
    canSave () {
      if (this.loading || this.formInvalid || !this.needSave) { return false }
      return true
    },
    sameAccounts() {
      if (this.showTransfer && this.transactionData.accountId && this.transferTransactionData.accountId && this.transactionData.accountId === this.transferTransactionData.accountId) { return true }
      return false
    },
    sameCurrency () {
      if (!this.showTransfer) { return true }
      if (this.transactionData.accountId && this.transferTransactionData.accountId && this.accounts[this.transactionData.accountId] && this.accounts[this.transferTransactionData.accountId] && this.accounts[this.transactionData.accountId].currencyCode === this.accounts[this.transferTransactionData.accountId].currencyCode) { return true }
      return false
    }
  },
  watch: {
    canUseTransfers(canUseTransfers) {
      if (!canUseTransfers) {
        this.clearTransferData()
      }
    },
    numberOfTransactionsCanAdd(number) {
      if (!number) {
        this.close()
        this.$store.commit('setAppShowField', { payWall: true })
        return
      }

      if (number === 1) {
        this.clearTransferData()
      }
    }
  },
  async created () {
    this.loading = true

    if (!this.numberOfTransactionsCanAdd) {
      this.close()
      this.$store.commit('setAppShowField', { payWall: true })
      return
    }

    if(!this.$store.getters.loadedAll('accountsActive')) {
      await this.$store.dispatch('fetchAccounts', true)
    }

    if(!this.$store.getters.loadedAll('cardsActive') && this.getLimitNumber('maxCards')) {
      await this.$store.dispatch('fetchCards', { active: true })
    }

    if(!this.$store.getters.loadedAll('banks') && this.getLimitNumber('maxBanks')) {
      await this.$store.dispatch('fetchBanks')
    }

    let data = { ...this.emptyData }
    let transfData = { ...this.emptyData }

    if (this.clickedTransactionId && this.transactions[this.clickedTransactionId]) {
      const transaction = this.transactions[this.clickedTransactionId]
      data = this.getDataFromTransaction(transaction)

      if (transaction.transferId && this.transactions[transaction.transferId] && this.numberOfTransactionsCanAdd > 1 && this.canUseTransfers) {
        this.showTransfer = true

        const transferTransaction = this.transactions[transaction.transferId]
        transfData = this.getDataFromTransaction(transferTransaction)
      }
    }

    this.transactionData = { ...data }
    this.transactionOldData = { ...this.emptyData }

    this.transferTransactionData = { ...transfData }
    this.transferTransactionOldData = { ...this.emptyData }

    if (this.clickedTransactionId && this.transactions[this.clickedTransactionId]) {
      if (this.transactions[this.clickedTransactionId].date) {
        this.transactionData.date = this.transactions[this.clickedTransactionId].date
      }

      if (this.transactions[this.clickedTransactionId].accountId && this.accounts[this.transactions[this.clickedTransactionId].accountId] && this.accounts[this.transactions[this.clickedTransactionId].accountId].active) {
        this.transactionData.accountId = this.transactions[this.clickedTransactionId].accountId

        if (this.transactions[this.clickedTransactionId].cardId !== undefined && this.getLimitNumber('maxCards')) {
          this.transactionData.cardId = this.transactions[this.clickedTransactionId].cardId
        } else {
          this.transactionData.cardId = null
        }
      } else {
        this.transactionData.accountId = null
        this.transactionData.cardId = null
      }

      if (this.transactions[this.clickedTransactionId].comment) {
        this.transactionData.comment = this.transactions[this.clickedTransactionId].comment
      }

      if (this.transactions[this.clickedTransactionId].transferId && this.transactions[this.transactions[this.clickedTransactionId].transferId] && this.canUseTransfers && this.numberOfTransactionsCanAdd > 1) {
        if (this.transactions[this.transactions[this.clickedTransactionId].transferId].accountId && this.accounts[this.transactions[this.transactions[this.clickedTransactionId].transferId].accountId] && this.accounts[this.transactions[this.transactions[this.clickedTransactionId].transferId].accountId].active) {
          this.transferTransactionData.accountId = this.transactions[this.transactions[this.clickedTransactionId].transferId].accountId

          if (this.transactions[this.transactions[this.clickedTransactionId].transferId].cardId !== undefined && this.getLimitNumber('maxCards')) {
            this.transferTransactionData.cardId = this.transactions[this.transactions[this.clickedTransactionId].transferId].cardId
          } else {
            this.transferTransactionData.cardId = null
          }
        } else {
          this.transferTransactionData.accountId = null
          this.transferTransactionData.cardId = null
        }

        if (this.transactions[this.transactions[this.clickedTransactionId].transferId].comment) {
          this.transferTransactionData.comment = this.transactions[this.transactions[this.clickedTransactionId].transferId].comment
        }
      }
    }

    if (!this.transactionData.date) {
      this.transactionData.date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    }
    this.newTransactionDate (+this.transactionData.date)

    this.loading = false
  },
  methods: {
    clearTransferData() {
      this.transferTransactionData = { ...this.emptyData }
      this.transferTransactionOldData = { ...this.emptyData }
      this.transferCommentValid = true
      this.showAddTagMenu = false
      this.showTransfer = false
    },
    getDataFromTransaction(transaction) {
      let data = { ...this.emptyData }

      if (transaction.accountId) { data.accountId = transaction.accountId }
      if (transaction.cardId && this.getLimitNumber('maxCards') && this.cards[transaction.cardId]) { data.cardId = transaction.cardId }
      if (transaction.comment) { data.comment = transaction.comment }
      if (transaction.date) { data.date = transaction.date }
      if (transaction.sum) { data.sum = transaction.sum }
      if (transaction.tags && transaction.tags.length) {
        for (const i in transaction.tags) {
          const tagId = transaction.tags[i]
          if (this.tags[tagId] && this.tags[tagId].active) {
            data.tags.push(tagId)
          }
        }
      }

      return data
    },
    setCurrency() {
      if (this.transactionData.accountId && this.accounts[this.transactionData.accountId] && this.accounts[this.transactionData.accountId].currencyCode) {
        return this.accounts[this.transactionData.accountId].currencyCode
      }
      return null
    },
    showAddTagMenuClicked(trns){
      if (trns && this[trns] && this[trns].tags && this[trns].tags.length >= this.getLimitNumber('tagsInTransaction')) {
        this.$store.commit('setAppShowField', { payWall: true })
        return
      }

      this.trns = trns
      this.showAddTagMenu = !this.showAddTagMenu
    },
    newTransactionDate(ms) {
      this.transactionData.date = new Date(ms)
      this.transferTransactionData.date = new Date(ms)

      if (+this.transactionData.date) { 
        this.dateValid = true
      } else {
        this.dateValid = false
      }
    },
    newTransactionSum(sum) {
      if ((sum !== 0) && (!sum || !(+sum) || isNaN(+sum))) {
        this.sumValid = false
        if (sum + '' === 'NaN') {
          this.transactionData.sum = null
          this.transferTransactionData.sum = null
        }
      } else {
        this.transactionData.sum = Math.round(+sum * 100)
        this.transferTransactionData.sum = Math.round(+sum * -100)
        this.sumValid = true
      }
    },
    setNewAccount({ accountId, cardId }) {
      this.transactionData.accountId = accountId
      this.transactionData.cardId = cardId

      if(this.transactionData.accountId && 
          this.transferTransactionData.accountId && 
          ((this.transactionData.accountId === this.transferTransactionData.accountId) || 
          (this.accounts[this.transferTransactionData.accountId] && 
          this.accounts[this.transactionData.accountId] && 
          this.accounts[this.transactionData.accountId].currencyCode !== this.accounts[this.transferTransactionData.accountId].currencyCode))
        ) {
        this.transferTransactionData.accountId = null
        this.transferTransactionData.cardId = null
      }
    },
    setNewTransferAccount({ accountId, cardId }) {
      this.transferTransactionData.accountId = accountId
      this.transferTransactionData.cardId = cardId
    },
    newComment(text, place) {
      text = text.trim()

      if (place === 'transactionData') {
        this.transactionData.comment = text
      
        if (!text || text.length <= this.checkValues('transactionComment', 'max')) {
          this.commentValid = true
        } else {
          this.commentValid = false
        }
      } else if (place === 'transferTransactionData') {
        this.transferTransactionData.comment = text
      
        if (!text || text.length <= this.checkValues('transactionComment', 'max')) {
          this.transferCommentValid = true
        } else {
          this.transferCommentValid = false
        }
      }
    },
    newTag({ tagId }) {
      if (!this[this.trns].tags) {
        this[this.trns].tags = []
      }

      if (!this[this.trns].tags.includes(tagId)) {
        this[this.trns].tags.push(tagId)
      }
    },
    newTagsCollection({ tagsCollectionId }) {
      if (tagsCollectionId && this.$store.getters.tagsCollections[tagsCollectionId]) {
        if (!this[this.trns].tags) {
          this[this.trns].tags = []
        }

        const tags = this.$store.getters.tagsCollections[tagsCollectionId].tags
        for (const tagId of tags) {
          if ((!this[this.trns].tags.includes(tagId)) && this[this.trns].tags.length < this.getLimitNumber('tagsInTransaction')) {
            this[this.trns].tags.push(tagId)
          }
        }
      }
    },
    deleteTag(tagId, type) {
      if (type && this[type] && this[type].tags && this[type].tags.includes(tagId)) {
        this[type].tags.splice(this[type].tags.indexOf(tagId), 1)
      }
    },
    changeAccounts () {
      if (!this.canUseTransfers || this.numberOfTransactionsCanAdd < 2) { return }
      if (!this.transactionData.accountId || !this.transferTransactionData.accountId) { return }

      const accountId1 = this.transactionData.accountId
      const cardId1 = this.transactionData.cardId

      const accountId2 = this.transferTransactionData.accountId
      const cardId2 = this.transferTransactionData.cardId
      
      this.transactionData.accountId = accountId2
      this.transactionData.cardId = cardId2

      this.transferTransactionData.accountId = accountId1
      this.transferTransactionData.cardId = cardId1

      logAnalyticsEvent('changeAccountsFromAddTransactionClicked')
    },
    async save() {
      let data = []
      data.push(this.transactionData)

      if (this.showTransfer && this.canUseTransfers && this.numberOfTransactionsCanAdd > 1) {
        data[0].transferId = true
        data.push(this.transferTransactionData)
        data[1].transferId = true
      }

      this.$emit('save-button-clicked', data)
      this.close()
    },
    async close() {
      this.closingModal = true

      setTimeout(() => {
        this.$emit('close-pannel')
        this.closingModal = false
      }, 300)
    },
    transferButtonClicked() {
      this.showTransfer = !this.showTransfer
      logAnalyticsEvent('transferButtonClicked')
    }
  } 
}
</script>